// @ts-nocheck
// (something is wrong with typings generated by css-loader plugin)
import { FunctionalComponent, ComponentChildren, h } from 'preact'
import * as style from './style.css'
import cn from 'classnames'

interface BoxElementInterface {
    children: ComponentChildren
    isFullHeight?: boolean
    isFullWidth?: boolean
    isContainer?: boolean
    flexDirection?: 'column' | 'row'
    justifyContent?:
        | 'start'
        | 'end'
        | 'center'
        | 'between'
        | 'around'
        | 'evenly'
    alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
}

const BoxElement: FunctionalComponent<BoxElementInterface> = ({
    children,
    flexDirection = 'row',
    justifyContent = 'start',
    alignItems = 'start',
    isFullHeight,
    isFullWidth,
    isContainer,
}: BoxElementInterface) => (
    <div
        class={cn(
            style.box,
            style[flexDirection],
            style[`justify-${justifyContent}`],
            style[`align-${alignItems}`],
            isFullHeight && style.fullHeight,
            isFullWidth && style.fullWidth,
            isContainer && style.container
        )}
    >
        {children}
    </div>
)

export default BoxElement
